<template>
    <v-container class="pt-md-0">
       <div class="row-itens">
        <div class="itens">
            <div class="logo">
                <Logo/>
            </div>
            <div class="text">
                <p v-if="$vuetify.breakpoint.mdAndUp">
                    Seja bem-vindo a Finago.<br>
                    Ficaremos felizes em conversar.
                    Preencha o formulário a seguir
                    e faça parte da revolução no
                    mercado da industria.</p>
                <p v-else>Seja bem-vindo a Finago. 
                    Preencha o formulário a seguir e 
                    faça parte da revolução no mercado 
                    da industria.</p>    
            </div>
            <RegisterStepper :e6="e6"/>
            </div>
       </div>
    </v-container>
</template>

<script>

import Logo from './Logo.vue';
import RegisterStepper from './RegisterStepper.vue';

export default {
  props: {
    e6: Number,
  },
  components: { Logo, RegisterStepper },
};
</script>

<style lang="scss" scoped>

.logo {
    width: 10%;
    align-content: left;
    margin-left: 9%;
    display: grid;
}

.text{
    font-size: 20px;
    font-family: 'Montserrat', 'Arial';
    color: #8C8C8C;
    margin-left: 10%;
    width: 70%;
    display: grid;
}

.row-itens{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media only screen and (max-device-width: 700px)  {
/* For mobile phones: */
    .text {
        width: 100%;
        text-align: center;
        margin-left: 1%;
        font-size: 18px;
        }
    .row-itens{
        margin-top: -10%;
    }
    .logo {
        padding-top: 20%;
    }
  }
</style>
