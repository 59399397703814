<template>
  <div id="app">
  <v-app id="inspire" v-if="$vuetify.breakpoint.mdAndUp">
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :color="e6 > 1 ? 'indigo darken-4' : 'pink darken-2'" :complete="e6 > 1" step="1">Dados cadastrais</v-stepper-step>
      <v-stepper-step :color="e6 > 2 ? 'indigo darken-4' : 'pink darken-2'" :complete="e6 > 2" step="2">Perfil da empresa</v-stepper-step>
      <v-stepper-step :color="e6 > 3 ? 'indigo darken-4' : 'pink darken-2'" :complete="e6 > 3" step="3">Dados do representante</v-stepper-step>
      <v-stepper-step :color="e6 > 4 ? 'indigo darken-4' : 'pink darken-2'" :complete="e6 > 4" step="4">Endereço do representante</v-stepper-step>
      <v-stepper-step :color="e6 > 5 ? 'indigo darken-4' : 'pink darken-2'" :complete="e6 > 5" step="5">Informações de pagamento</v-stepper-step>
      <v-stepper-step :color="e6 > 6 ? 'indigo darken-4' : 'pink darken-2'" step="6">Acesso Finago</v-stepper-step>
    </v-stepper>
  </v-app>
  <v-col cols="12" class="pl-8 pr-8" v-else>
    <v-col align="center" class="pb-10">
      <v-row justify="center">
        <span class="step">{{e6}}</span>
        <span class="step-text pl-2" v-if="e6 === 1">Dados cadastrais</span>
        <span class="step-text pl-2" v-if="e6 === 2">Perfil da empresa</span>
        <span class="step-text pl-2" v-if="e6 === 3">Dados do representante</span>
        <span class="step-text pl-2" v-if="e6 === 4">Endereço do representante</span>
        <span class="step-text pl-2" v-if="e6 === 5">Informações de pagamento</span>
        <span class="step-text pl-2" v-if="e6 === 6">Acesso Finago</span>
      </v-row>
    </v-col>
    <v-row>
      <v-icon :color="e6 ===  1 ? 'pink darken-2' : e6  > 1 ? 'indigo darken-4' : 'blue-grey lighten-4'" class="icon" small>mdi-checkbox-blank-circle</v-icon>
      <v-divider :color="e6 ===  1 ? '' : e6  > 1 ? 'indigo darken-4' : ''" class="divider"></v-divider>
      <v-icon :color="e6 ===  2 ? 'pink darken-2' : e6  > 2 ? 'indigo darken-4' : 'blue-grey lighten-4'" class="icon" small>mdi-checkbox-blank-circle</v-icon>
      <v-divider :color="e6 ===  2 ? '' : e6  > 2 ? 'indigo darken-4' : ''" class="divider"></v-divider>
      <v-icon :color="e6 ===  3 ? 'pink darken-2' : e6  > 3 ? 'indigo darken-4' : 'blue-grey lighten-4'" class="icon" small>mdi-checkbox-blank-circle</v-icon>
      <v-divider :color="e6 ===  3 ? '' : e6  > 3 ? 'indigo darken-4' : ''" class="divider"></v-divider>
      <v-icon :color="e6 ===  4 ? 'pink darken-2' : e6  > 4 ? 'indigo darken-4' : 'blue-grey lighten-4'" class="icon" small>mdi-checkbox-blank-circle</v-icon>
      <v-divider :color="e6 ===  4 ? '' : e6  > 4 ? 'indigo darken-4' : ''" class="divider"></v-divider>
      <v-icon :color="e6 ===  5 ? 'pink darken-2' : e6  > 5 ? 'indigo darken-4' : 'blue-grey lighten-4'" class="icon" small>mdi-checkbox-blank-circle</v-icon>
      <v-divider :color="e6 ===  5 ? '' : e6  > 5 ? 'indigo darken-4' : ''" class="divider"></v-divider>
      <v-icon :color="e6 ===  6 ? 'pink darken-2' : e6  > 6 ? 'indigo darken-4' : 'blue-grey lighten-4'" class="icon" small>mdi-checkbox-blank-circle</v-icon>
    </v-row>
  </v-col>
</div>
</template>

<script>
export default {
  props: {
    e6: Number,
  },
};

</script>

<style lang="scss" scoped>

  ::v-deep .v-application--is-ltr .v-stepper--vertical .v-stepper__step__step {
    font-weight: bold;
    font-size: 14px;
    padding: 3%;
  }
  ::v-deep .v-stepper.v-stepper--vertical.theme--light {
    box-shadow: none;
  }
  ::v-deep .theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
    background: #C4C4C4;
    font-weight: bold;
    font-size: 14px;
    padding: 3%;
  }
  ::v-deep .theme--light.v-stepper .v-stepper__label {
    color: #C4C4C4;
    font-weight: bold;
    font-size: 20px;
  }
  ::v-deep .theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
    text-shadow: none;
    color: #D70472;
    font-weight: bold;
    font-size: 20px;
  }
  ::v-deep .theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
    color: #282E62;
    font-weight: bold;
    font-size: 20px;
  }
  ::v-deep .v-stepper--vertical .v-stepper__step {
    padding: 10px 10% 16px;
  }
  ::v-deep .v-stepper--vertical {
    padding-bottom: 10px;
  }
  ::v-deep .v-application--wrap {
    min-height: 50%;
  }
     @media only screen and (max-device-width: 700px)  {
    /* For mobile phones: */
    .divider {
      margin-top: 8px;
    }
    .icon {
      color: #C4C4C4;
    }
    span.step {
      background: #D70472;
      border-radius: 0.8em;
      -moz-border-radius: 0.8em;
      -webkit-border-radius: 0.8em;
      color: #ffffff;
      display: inline-block;
      font-weight: bold;
      line-height: 1.6em;
      margin-right: 5px;
      text-align: center;
      width: 1.6em; 
    }
    .step-text {
      color: #D70472;
      font-size: 16px;
      font-weight: bold;
    }
  }
</style>
