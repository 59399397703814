<template>
  <div class="top-line">
    <v-container class="forms pt-0 pb-0">
      <RegisterSupplier :e6="stepper" />
      <v-col class="form pb-0" md="5">
        <FirstStep v-model="form1" v-if="stepper === 1" />
        <SecondStep v-else-if="stepper === 2" />
        <ThirdStep v-else-if="stepper === 3" />
        <FourthStep v-else-if="stepper === 4" />
        <FifthStep v-else-if="stepper === 5" />
        <SixthStep v-else />
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import RegisterSupplier from "../../../components/RegisterSupplier.vue";
import FirstStep from "./FirstStep.vue";
import SecondStep from "./SecondStep.vue";
import ThirdStep from "./ThirdStep.vue";
import FourthStep from "./FourthStep.vue";
import FifthStep from "./FifthStep.vue";
import SixthStep from "./SixthStep.vue";

export default {
  components: {
    RegisterSupplier,
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    FifthStep,
    SixthStep,
  },
  data() {
    return {
      stepper: 1,
      form1: {},
    };
  },
  methods: {
    ...mapActions("Registration", ["setBanks", "setSegments"]),
    ...mapMutations({
      clearDataRegistration: "Registration/CLEAR_DATA_REGISTRATION",
    }),
  },
  created() {
    this.$bus.$on("nextStepper", () => {
      this.stepper += 1;
    });
    this.$bus.$on("previousStepper", () => {
      this.stepper -= 1;
    });
    this.setBanks();
    this.setSegments();
  },
  destroyed() {
    this.clearDataRegistration();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/login/registration/registration.scss";
</style>
