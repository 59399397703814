<template>
  <v-form ref="form" :lazy-validation="lazy" @submit.prevent="submit">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            class="dataRegistration pb-md-3 pt-0"
            v-model="dataRegistration.representative.name"
            :rules="validationUserName"
            label="Nome do representante"
            placeholder="Ex: Maria Zilda de Sousa"
            required
          ></v-text-field>
          <v-text-field
            class="dataRegistration pb-md-3 pt-0"
            v-model="dataRegistration.representative.document"
            :rules="validationCpf"
            label="CPF do Representante"
            placeholder="CPF do representante"
            v-mask="'###.###.###-##'"
            required
          ></v-text-field>
          <v-text-field
            class="dataRegistration pb-md-3 pt-0"
            v-model="dataRegistration.representative.birthDate "
            :rules="validationDateOfBirth"
            label="Data de Nascimento"
            placeholder="00/00/0000"
            v-mask="'##/##/####'"
            required
          ></v-text-field>
            <v-text-field
            class="dataRegistration pb-md-3 pt-0"
            v-model="dataRegistration.representative.motherName"
            :rules="validationMotherName"
            label="Nome da mãe do Representante"
            placeholder="Ex: Roberta Sousa"
            required
          ></v-text-field>
          <v-row class="buttons">
            <v-col cols="12" md="12" class="pa-md-1 pt-10">
                <v-btn rounded large block color="#D70472" dark @click="next">Continuar</v-btn>
            </v-col>
            <v-col md="12" align="center" @click="back">
              <span class="back-button">Voltar</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mask } from "vue-the-mask";
import service from "../../../service";

export default {
  name: "ThirdStep",
  directives: { mask },
  data: () => ({
    lazy: false,
  }),
  computed: {
    validationUserName() {
      return [(valueInput) => !!valueInput || "Nome obrigatório"];
    },
    validationCpf() {
      return [
        (valueInput) => !!valueInput || "CPF obrigatório",
        (valueInput) =>
          (valueInput && valueInput.length >= 14) || "Deve conter 11 dígitos",
      ];
    },
    validationMotherName() {
      return [(valueInput) => !!valueInput || "Nome obrigatório"];
    },
    validationDateOfBirth() {
      return [
        (valueInput) => !!valueInput || "Data obrigatória",
        (valueInput) =>
          (valueInput && valueInput.length >= 10) || "Deve conter 8 dígitos",
        (valueInput) =>
          this.$moment(valueInput, "DD/MM/YYYY").isValid() || "Data inválida",
        (valueInput) => {
          const currentDate = this.$moment().format();
          const parseDate = this.$moment(valueInput, "DD/MM/YYYY").format();
          return (
            this.$moment(parseDate).isSameOrBefore(currentDate) ||
            "Data inválida (Superior á atual)"
          );
        },
        (valueInput) => {
          const currentDate = this.$moment().format();
          const parseDate = this.$moment(valueInput, "DD/MM/YYYY").format();
          return (
            this.$moment(currentDate).diff(parseDate, "years") >= 18 ||
            "Data inválida (Deve ter mais de 18 anos)"
          );
        },
      ];
    },
    dataRegistration() {
      return this.$store.state.Registration.dataRegistration;
    },
  },
  methods: {
    next() {
      if (this.$refs.form.validate()) {
        this.dataRegistration.representative.document = service.cleanDocument(
          this.dataRegistration.representative.document,
        );
        this.$bus.$emit("nextStepper");
      }
    },
    back() {
      this.$bus.$emit("previousStepper");
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/login/registration/otherSteps.scss';
</style>
