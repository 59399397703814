<template>
  <v-form ref="form" :lazy-validation="lazy" @submit.prevent="submit">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-col md="6" cols="6" class="pl-0 pr-0 pb-md-5 pb-0">
            <v-select
              v-model="setProfile"
              :items="profile"
              label="Perfil da Empresa"
              placeholder="Selecione"
              :rules="validationProfile"
            ></v-select>
          </v-col>

          <v-col
            class="pl-0 pr-0 pb-md-5"
            v-if="dataRegistration.profile[0] === 'seller'"
          >
            <v-select
              class="dataRegistration"
              v-model="dataRegistration.captureFinancialData"
              :items="selectSolution"
              item-text="text"
              item-value="value"
              :rules="validationSolution"
              @change="getPosProperty"
              label="Solução de Captura"
              dense
            >
            </v-select>
          </v-col>

          <v-col
            class="pl-0 pr-0 pb-md-5 pb-0 pt-md-5"
            v-if="
              dataRegistration.captureFinancialData === 'POS' &&
              dataRegistration.profile[0] === 'seller'
            "
          >
            <v-select
              v-model="dataRegistration.posQuantity"
              :items="dataPos"
              item-text="value"
              :rules="validationPosQuantity"
              label="Quantidade de POS"
              dense
            >
            </v-select>
          </v-col>

          <v-col
            class="pl-0 pr-0"
            v-if="dataRegistration.profile[0] === 'seller'"
          >
            <v-select
              class="select-box"
              v-model="dataRegistration.relatedCustomer"
              multiple
              label="Selecione os seus fornecedores"
              :rules="validationRelatedCustomer"
              :items="suppliers"
              item-text="name"
              item-value="document"
            >
              <template
                v-slot:selection="{ item }"
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                <v-chip>
                  <span class="list-items">{{ item.name }}</span>
                </v-chip>
              </template>
              <template
                class="items-chips"
                v-slot:selection="{ item, index }"
                v-else
              >
                <v-chip small v-if="index < 3">
                  <span class="list-items">{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 3" class="grey--text caption"
                  >(+{{ dataRegistration.relatedCustomer.length - 3 }})</span
                >
              </template>
            </v-select>
          </v-col>
          <v-text-field 
            :class="!dataRegistration.relatedCustomer || dataRegistration.relatedCustomer.length < 2 ? 'dataRegistration pb-md-5 pt-0': 'dataRegistration-monthly pb-md-5 pt-0'"
            v-model.lazy="dataRegistration.monthlyIncome"
            label="Faturamento Mensal"
            placeholder="Insira o faturamento mensal"
            :rules="validationMonthlyIncome"
            v-money="money"
            validate-on-blur
            required
          ></v-text-field>
          <v-select
            class="dataRegistration mt-3"
            v-model="dataRegistration.segment"
            :items="segments"
            :rules="validationSegment"
            label="Segmento"
            placeholder="Selecione o segmento"
            @change="changeSegment"
            item-text="value"
            item-value="key"
          ></v-select>
          <v-row class="pb-md-5">
            <v-col cols="6" md="6">
              <v-text-field
                v-model="dataRegistration.phone"
                label="Telefone"
                v-mask="'(##)####-####'"
                placeholder="(11) 0000-0000"
                :rules="validationPhoneNumber"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                v-model="dataRegistration.mobilePhone"
                label="Celular"
                v-mask="'(##)#####-####'"
                placeholder="(11) 0 0000-0000"
                :rules="validationMobileNumber"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="buttons">
            <v-col cols="12" md="12" class="pa-md-1 pt-10">
              <v-btn rounded large block color="#D70472" dark @click="next"
                >Continuar</v-btn
              >
            </v-col>
            <v-col md="12" align="center" @click="back">
              <span class="back-button">Voltar</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mask } from "vue-the-mask";
import { VMoney } from "v-money";
import { mapMutations, mapGetters, mapActions } from "vuex";
import service from "../../../service";

export default {
  name: "SecondStep",
  directives: {
    mask,
    money: VMoney,
  },
  data: () => ({
    stepper: 2,
    setProfile: "",
    titleFirstItems: "Segmento",
    multipleSelect: false,
    select: false,
    profile: ["Lojista", "Fornecedor"],
    lazy: false,
    checkSeller: false,
    checkSupplier: false,
    ruleProfile: false,
    ruleSupplier: false,
    selectSolution: [
      { text: "Equipamento integrado ao PDV (TEF)", value: "TEF" },
      { text: "Máquina de Cartão (POS)", value: "POS" },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
    },
    nextStepper: false,
  }),
  computed: {
    ...mapGetters({
      suppliers: "Registration/getSuppliers",
      segments: "Registration/getSegments",
      dataPos: "Registration/getDomainPropertys",
    }),
    validationUserName() {
      return [(valueInput) => !!valueInput || "Nome obrigatório"];
    },
    validationProfile() {
      return [(valueInput) => !!valueInput || "Perfil obrigatório"];
    },
    validationSegment() {
      return [(valueInput) => !!valueInput || "Segmento obrigatório"];
    },
    validationSolution() {
      return [(valueInput) => !!valueInput || "Solução obrigatória"];
    },
    validationPosQuantity() {
      return [(valueInput) => !!valueInput || "Quantidade obrigatória"];
    },
    validationRelatedCustomer() {
      return [(valueInput) => valueInput.length >= 1 || "Fornecedores obrigatório"];
    },
    validationPhoneNumber() {
      return [
        (valueInput) => !!valueInput || "Número de telefone obrigatório",
        (valueInput) =>
          (valueInput && valueInput.length >= 13) || "Deve conter 10 números",
      ];
    },
    validationMobileNumber() {
      return [
        (valueInput) => !!valueInput || "Número de celular obrigatório",
        (valueInput) =>
          (valueInput && valueInput.length >= 14) || "Deve conter 11 números",
      ];
    },
    validationMonthlyIncome() {
      return [
        (valueInput) =>
          valueInput !== "R$ 0,00" || "Faturamento mensal obrigatório",
      ];
    },
    dataRegistration() {
      return this.$store.state.Registration.dataRegistration;
    },
  },
  methods: {
    ...mapMutations("Registration", ["setValueSelectDataRegistration"]),
    ...mapActions("Registration", [
      "getSuppliers",
      "domainProperty",
      "setSegments",
    ]),
    openMultipleSelect() {
      if (this.multipleSelect === true) {
        this.multipleSelect = false;
      } else {
        this.multipleSelect = true;
      }
    },
    verifyProfile() {
      if (!this.dataRegistration.profile) {
        this.dataRegistration.profile = [];
      } else if (this.dataRegistration.profile.includes("seller")) {
        this.checkSeller = true;
        this.checkSupplier = false;
      } else if (this.dataRegistration.profile.includes("supplier")) {
        this.checkSupplier = true;
        this.checkSeller = false;
      } else {
        this.checkSupplier = false;
        this.checkSeller = false;
      }
    },
    changeProfile(value) {
      this.dataRegistration.profile = [];
      this.dataRegistration.profile.push(value);
      if (value === "supplier") {
        this.checkSupplier = true;
        this.checkSeller = false;
        delete this.dataRegistration.relatedCustomer;
        delete this.dataRegistration.posQuantity;
        delete this.dataRegistration.captureFinancialData;
        this.setSegments("supplier");
      } else {
        this.checkSeller = true;
        this.checkSupplier = false;
        this.setSegments("seller");
      }
    },
    next() {
      if (this.checkSeller === false && this.checkSupplier === false) {
        this.ruleProfile = true;
      } else if (
        this.checkSeller === true &&
        (this.dataRegistration.relatedCustomer === undefined ||
          this.dataRegistration.relatedCustomer.length < 1)
      ) {
        this.ruleProfile = false;
        this.ruleSupplier = true;
      } else {
        this.ruleProfile = false;
        this.ruleSupplier = false;
      }
      this.nextStepper = true;

      if (this.$refs.form.validate() && this.dataRegistration.segment) {
        if (
          (this.dataRegistration.relatedCustomer &&
            this.dataRegistration.relatedCustomer.length >= 1) ||
          this.dataRegistration.profile.includes("supplier")
        ) {
          this.dataRegistration.phone = service.cleanDocument(
            this.dataRegistration.phone
          );
          this.dataRegistration.mobilePhone = service.cleanDocument(
            this.dataRegistration.mobilePhone
          );
          this.$bus.$emit("nextStepper");
        }
      }
    },
    back() {
      this.$bus.$emit("previousStepper");
    },
    changeSegment(segment) {
      this.segment = segment;
      this.dataRegistration.segment = segment;
    },
    changeRelatedValues(array) {
      this.dataRegistration.relatedCustomer = array;
    },
    async pushSuppliers() {
      await this.getSuppliers();
    },
    async getPosProperty() {
      if (this.dataRegistration.captureFinancialData === "POS")
        await this.domainProperty();
    },
  },
  watch: {
    setProfile(value) {
      if (value === "Lojista") {
        this.changeProfile("seller");
      } else if (value === "Fornecedor") {
        this.changeProfile("supplier");
      }
    },
  },
  created() {
    this.pushSuppliers();
    this.verifyProfile();
    if (this.dataRegistration.profile[0] === "seller") {
      this.setProfile = "Lojista";
    } else if (this.dataRegistration.profile[0] === "supplier") {
      this.setProfile = "Fornecedor";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/login/registration/otherSteps.scss";
</style>
