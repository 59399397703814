<template>
  <v-form ref="form" :lazy-validation="lazy"  @submit.prevent="submit">
    <v-container class="pt-md-10">
      <v-row>
        <v-col cols="12">
          <v-select
            class="dateRegistration pb-md-5"
            v-model="dataRegistration.bank"
            :items="banksMobile"
            :rules="validationBank"
            label="Banco"
            placeholder="Selecione o banco"
            @change="changeBank"
            item-text="value"
            item-value="key"
            required
          ></v-select>
          <v-row class="pb-5">
            <v-col md="6">
              <v-text-field
                v-model="dataRegistration.agency"
                label="Agência"
                placeholder="Inserir Agência"
                :rules="validationAgency"
                v-mask="'#####'"
                required
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                class="dateRegistration"
                v-model="accountNumber"
                placeholder="Inserir Conta e Dígito"
                label="Conta e dígito"
                validate-on-blur
                :rules="validationAccountNumber"
                v-mask="'##############'"
                required
            ></v-text-field>
            </v-col>
          </v-row>
          <v-col md="6" cols="6" class="pl-0 pb-md-5">
            <v-select
              class="dateRegistration"
              v-model="dataRegistration.accountType"
              :items="accountTypes"
              :rules="validationAccountType"
              label="Tipo de Conta"
              placeholder="Selecione"
              @change="changeAccountTypes"
              item-text="value"
              item-value="id"
            ></v-select>
          </v-col>
          <v-row class="buttons">
            <v-col cols="12" md="12" class="pa-md-1 pt-10">
              <div class="next-button">
                <v-btn rounded large block color="#D70472" dark @click="next">Continuar</v-btn>
              </div>
            </v-col>
            <v-col md="12" align="center" @click="back">
                <span class="back-button">Voltar</span>
            </v-col>
         </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>

import { mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import accountTypes from './consts';

export default {
  name: 'ThirdStep',
  directives: { mask },
  data: () => ({
    lazy: false,
    accountTypes,
    accountNumber: "",
    digit: "",
    
  }),
  computed: {
    ...mapGetters('Registration', ['getBanks']),
    validationBank() {
      return [valueInput => !!valueInput || 'Banco obrigatório'];
    },
    validationAccountType() {
      return [valueInput => !!valueInput || 'Tipo de conta obrigatório'];
    },
    validationAgency() {
      return [valueInput => !!valueInput || 'Agência obrigatória'];
    },
    validationAccountNumber() {
      return [
        (valueInput) => !!valueInput || 'Número da conta obrigatório',
        (valueInput) => valueInput.length >= 2 || 'Insira um número válido',
      ];
    },
    dataRegistration() {
      return this.$store.state.Registration.dataRegistration;
    },
    banksMobile() {
      const newArray = [];
      this.getBanks.map(el => (newArray.push({ value: (el.value.length > 40 ? `${el.value.substr(0, 40)}...` : `${el.value.substr(0, 40)}`), key: el.key })));
      return newArray;
    },
    banksDesktop() {
      return this.getBanks;
    },
  },
  watch: {
    accountNumber(value){
      this.dataRegistration.accountNumber = value
      this.digit = value.slice(-1)
      this.dataRegistration.accountDigitNumber = this.digit
    }
  },
  methods: {
    next() {
      if (
        this.$refs.form.validate()
        && this.dataRegistration.bank
        && this.dataRegistration.accountType
      ) {
        this.$bus.$emit('nextStepper');
      }
    },
    back() {
      this.$bus.$emit('previousStepper');
    },
    changeBank(bank) {
      this.bank = bank;
      this.dataRegistration.bank = bank;
    },
    changeAccountTypes(accountType) {
      this.accountType = accountType;
      this.dataRegistration.accountType = accountType;
    },
  },
    created() {
    if (this.dataRegistration.accountType) {
      this.accountNumber = this.dataRegistration.accountNumber;
      this.accountDigitNumber = this.dataRegistration.accountDigitNumber;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/login/registration/otherSteps.scss';
</style>
