<template>
  <v-form ref="form" class="pt-0" :lazy-validation="lazy" @submit.prevent="submit">
    <v-container class="pl-5 pr-5 pl-md-2 pr-md-2 pb-0">
      <v-row v-if="error">
        <v-alert class="alert" dense outlined>{{error}}</v-alert>
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <v-text-field 
            v-model="document"
            label="CNPJ"
            placeholder="Insira seu CNPJ"
            v-mask="'##.###.###/####-##'"
            :rules="validationCNPJ"
            required
            :readonly="disableDocument"
          ></v-text-field>
        </v-col>
        <v-col cols="4" v-if="this.showChange">
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            @click="handleClickClear()"
            outlined
            rounded
            color="#D70472"
          >Alterar CNPJ</v-btn>
          <v-btn
            v-else
            @click="handleClickClear()"
            outlined
            rounded
            color="#D70472"
          >Alterar</v-btn>
        </v-col>
      </v-row>
      <p class="document-alert pt-3 pt-md-0" v-if="this.loading">Carregando CNPJ em nosso banco de dados.</p>
      <v-row class="pt-md-3 pb-md-3">
        <v-text-field
          class="dataRegistration"
          v-model="dataRegistration.nickName"
          label="Nome Fantasia"
          placeholder="Insira o Nome Fantasia"
          :single-line="singleLineStatus"
          readonly
        ></v-text-field>
      </v-row>
      <v-row class="pb-md-3">
        <v-text-field
          class="dataRegistration"
          v-model="dataRegistration.name"
          label="Razão Social"
          placeholder="Insira a Razão Social"
          :single-line="singleLineStatus"
          :rules="validationName"
          readonly
        ></v-text-field>
      </v-row>
      <v-row class="dataRegistration pb-md-0 mt-md-0">
        <v-col md="10" class="pl-md-0">
          <v-text-field
            v-model.lazy="dataRegistration.stateSubscription"
            @change="validateStateSbscription"
            :rules="validationStateSubscription"
            label="Inscrição Estadual"
            placeholder="Inscrição Estadual"
            id="stateSubscription"
            type="number"
            required
            :readonly="disabledState"
          ></v-text-field>
        </v-col>
        <v-col md="2" class="radio-item" v-if="$vuetify.breakpoint.mdAndUp">
          <v-row>
            <input
              :checked="checkedRadio"
              :disabled="disabledCheck"
              type="radio"
              id="ritema"
              name="ritem"
              @click="alterRadio"
            />
            <label for="ritema">Isento</label>
          </v-row>
        </v-col>
        <v-col cols="4" v-else>
          <v-btn
            class="form-inline"
            :disabled="disabledCheck"
            @click="alterRadio"
            :outlined = "!checkedRadio"
            :dark = "checkedRadio" 
            rounded
            color="#8C8C8C"
          >Isento</v-btn>
        </v-col>
      </v-row>
      <v-row class="pb-md-3">
        <v-text-field
          class="dataRegistration"
          v-model="concatAdress"
          label="Endereço"
          placeholder="Insira o Endereço"
          :single-line="singleLineStatus"
          :rules="validationAdress"
          readonly
        ></v-text-field>
      </v-row>
      <v-row class="buttons">
        <v-col cols="12" md="12" class="pa-md-1">
            <v-btn rounded large block color="#D70472" dark @click="next">Continuar</v-btn>
        </v-col>
        <v-col md="12" align="center" @click="back">
          <span class="back-button">Voltar</span>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mask } from "vue-the-mask";
import { mapMutations, mapActions, mapGetters } from "vuex";
import service from "../../../service";

export default {
  name: "FirstStep",
  directives: { mask },
  data: () => ({
    service,
    document: "",
    disabledState: false,
    lazy: false,
    oldStateSubscription: "",
    checkedRadio: false,
    disabledCheck: false,
    validateInput: false,
    singleLineStatus: false,
    loading: false,
    showChange: false,
    disableDocument: false,
    concatAdress: "",
  }),
  methods: {
    ...mapMutations({
      clearDataRegistration: "Registration/CLEAR_DATA_REGISTRATION",
      setError: "Registration/SET_ERROR",
      clearError: "Registration/CLEAR_ERROR",
    }),
    ...mapActions("Registration", ["getDocument"]),
    async handleChange() {
      if (this.document && this.document.length === 18) {
        this.loading = true;
        this.disableDocument = true;
        await this.getDocument(this.service.cleanDocument(this.document));
        this.showChange = true;
        this.singleLineStatus = false;
        this.loading = false;
        if (!this.error) {
          this.populateFormData(this.dataDocument);
        }
      }
    },
    validateStateSbscription(value) {
      if (value.length > 0) {
        this.disabledCheck = true;
      } else {
        this.disabledCheck = false;
      }
    },
    alterRadio() {
      if (!this.checkedRadio) {
        this.checkedRadio = true;
        this.oldStateSubscription = this.dataRegistration.stateSubscription;
        this.dataRegistration.stateSubscription = null;
        this.disabledState = true;
      } else {
        this.dataRegistration.stateSubscription = this.oldStateSubscription;
        this.checkedRadio = false;
        this.disabledState = false;
      }
    },

    next() {
      if (
        this.$refs.form.validate() &&
        ((this.dataRegistration.stateSubscription == null &&
          this.checkedRadio === true) ||
          (this.dataRegistration.stateSubscription &&
            this.checkedRadio === false)) &&
        this.dataRegistration.name
      ) {
        this.dataRegistration.document = this.document;
        this.$bus.$emit("nextStepper");
      }
    },
    back() {
      this.$router.push({ path: "login" });
    },
    populateFormData(data) {
      const {
        name,
        nickName,
        stateSubscription,
        address,
        number,
        neighborhood,
        city,
        state,
      } = data;

      this.dataRegistration.name = name;
      this.dataRegistration.nickName = nickName;
      if (stateSubscription) {
        this.dataRegistration.stateSubscription = stateSubscription;
        this.disabledState = true;
        this.checkedRadio = false;
        this.disabledCheck = true;
      } else {
        this.disabledCheck = false;
        this.checkedRadio = false;
        this.disabledState = false;
      }
      this.dataRegistration.address = address;
      this.dataRegistration.number = number;
      this.dataRegistration.neighborhood = neighborhood;
      this.dataRegistration.city = city;
      this.dataRegistration.state = state;
      this.concatAdress = `${address}, ${number} - ${neighborhood} - ${city} - ${state}`;
    },
    handleClickClear() {
      this.document = "";
      this.disabledState = false;
      this.disabledCheck = false;
      this.clearDataRegistration();
      this.concatAdress = "";
      this.disableDocument = false;
      this.clearError();
      this.showChange = false;
    },
    send() {
      this.$emit("sendForm", this.accountSettings);
    },
  },

  computed: {
    ...mapGetters({
      error: "Registration/getError",
      dataDocument: "Registration/getDataDocument",
    }),
    validationCNPJ() {
      return [
        (value) => !!value || "CNPJ obrigatório",
        (value) => (value && value.length > 17) || "Deve conter 14 números",
      ];
    },
    dataRegistration() {
      return this.$store.state.Registration.dataRegistration;
    },
    validationStateSubscription() {
      return [
        (valueInput) =>
          !!valueInput ||
          this.checkedRadio ||
          "Informe a inscrição ou marque isento",
      ];
    },
    validationName() {
      return [
        (valueInput) =>
          !!valueInput || this.checkedRadio || "Razão social obrigatório",
      ];
    },
    validationAdress() {
      return [
        (valueInput) =>
          !!valueInput || this.checkedRadio || "Endereço obrigatório",
      ];
    },
  },

  watch: {
    document(value) {
      if (!this.dataRegistration.name) {
        this.handleChange(value);
      }
    },
    checkedRadio() {
      if (this.checkedRadio === true) {
        this.dataRegistration.stateSubscription = null;
      }
    },
  },
  created() {
    this.document = this.dataRegistration.document;
    if (this.document) {
      this.concatAdress = `${this.dataRegistration.address}, ${this.dataRegistration.number} -
      ${this.dataRegistration.neighborhood} -
      ${this.dataRegistration.city} -
      ${this.dataRegistration.state}`;
      this.showChange = true;
      if(this.dataRegistration.stateSubscription !== null) {
        this.checkedRadio = false;
      } else {
        this.checkedRadio = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/login/registration/firstStep.scss";
</style>
