<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-container class="pb-0 pt-0">
      <v-row>
        <v-col class="pb-0 pt-0" cols="12">
          <v-col md="6" cols="12" class="pl-0 pr-0">
            <v-text-field
              v-model="zipCode"
              :rules="validationCep"
              label="CEP"
              placeholder="Insira o CEP"
              required
              v-mask="'#####-###'"
            ></v-text-field>
          </v-col>
          <v-text-field
            v-model="residentialAdress"
            label="Endereço"
            placeholder="Ex: Rua João Moura"
            :readonly="readStreet"
            :rules="validationResidentialAdress"
            required
          ></v-text-field>
          <v-row class="pa-0">
            <v-col md="6">
              <v-text-field
                v-model="residentialNumber"
                label="Número"
                placeholder="Ex: 1230"
                :rules="validationResidentialNumber"
                required
                v-mask="'#####'"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="residentialComplement"
                label="Complemento"
                placeholder="Ex: Bloco A"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            class="pb-md-5"
            v-model="residentialNeighborhood"
            label="Bairro"
            placeholder="Ex: Pinheiros"
            :readonly="readNeighborhood"
            :rules="validationResidentialNeighborhood"
            required
          ></v-text-field>
          <v-text-field
            class="pb-md-5"
            v-model="residentialCity"
            label="Cidade"
            readonly
            placeholder="Ex: São Paulo"
            required
          ></v-text-field>
          <v-text-field
            class="pb-md-3"
            v-model="residentialState"
            label="Estado"
            readonly
            placeholder="Ex: São Paulo"
            required
          ></v-text-field>
          <v-row class="buttons">
            <v-col cols="12" md="12" class="pa-md-1 pt-10">
                <v-btn rounded large block color="#D70472" dark @click="next">Continuar</v-btn>
            </v-col>
            <v-col md="12" align="center" @click="back">
              <span class="back-button">Voltar</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mask } from "vue-the-mask";
import { mapMutations, mapActions, mapGetters } from "vuex";
import service from "../../../service";

export default {
  name: "FourthStep",
  directives: { mask },
  data: () => ({
    service,
    lazy: false,
    zipCode: "",
    address: {},
    readStreet: true,
    readNeighborhood: true,
    residentialAdress: "",
    residentialNeighborhood: "",
    residentialCity: "",
    residentialState: "",
    residentialNumber: "",
    residentialComplement: "",
  }),
  computed: {
    ...mapGetters({
      error: "Registration/getError",
      getAddress: "Registration/getAddress",
    }),
    dataRegistration() {
      return this.$store.state.Registration.dataRegistration;
    },
    validationCep() {
      return [
        (valueInput) => !!valueInput || "CEP obrigatório",
        (valueInput) =>
          (valueInput && valueInput.length >= 9) || "Deve conter 8 dígitos",
      ];
    },
    validationResidentialAdress() {
      return [(valueInput) => !!valueInput || "Endereço obrigatório"];
    },
    validationResidentialNumber() {
      return [(valueInput) => !!valueInput || "Número obrigatório"];
    },
    validationResidentialComplement() {
      return [(valueInput) => !!valueInput || "Complemento obrigatório"];
    },
    validationResidentialNeighborhood() {
      return [(valueInput) => !!valueInput || "Bairro obrigatório"];
    },
    validationResidentialCity() {
      return [(valueInput) => !!valueInput || "Cidade obrigatória"];
    },
    validationResidentialState() {
      return [(valueInput) => !!valueInput || "Estado obrigatório"];
    },
  },
  watch: {
    zipCode(value) {
      if (value.length === 9) {
        this.handleChange(value);
        this.dataRegistration.representative.address.zipCode = value;
      }
    },
    residentialNumber(value) {
      this.dataRegistration.representative.address.number = value;
    },
    residentialComplement(value) {
      this.dataRegistration.representative.address.complement = value;
    },
    residentialNeighborhood(value) {
      this.dataRegistration.representative.address.neighborhood = value;
    },
    residentialAdress(value) {
      this.dataRegistration.representative.address.street = value;
    },
  },
    methods: {
    ...mapMutations({
      setAdress: "Registration/SET_ADDRESS",
      setError: "Registration/SET_ERROR",
      clearError: "Registration/CLEAR_ERROR",
    }),
    ...mapActions({
      getPostalCode: "Registration/getPostalCode",
    }),
    async handleChange() {
      if (this.zipCode && this.zipCode.length === 9) {
        await this.getPostalCode(this.zipCode);
        if (!this.error) {
          this.populateFormData(this.getAddress);
          this.readStreet = this.getAddress.street !== '' ;
          this.readNeighborhood = this.getAddress.neighborhood !== '';
        }
      }
    },
    populateFormData(data) {
      const { street, city, state, neighborhood } = data;

      this.residentialCity = city;
      this.residentialState = state;
      this.dataRegistration.representative.address.city = city;
      this.dataRegistration.representative.address.state = state;
      if(this.getAddress.street !== ''){
        this.residentialAdress = street;
        this.dataRegistration.representative.address.street = street;
      }
      if(this.getAddress.neighborhood !== ''){
        this.residentialNeighborhood = neighborhood;
        this.dataRegistration.representative.address.neighborhood = neighborhood;
      }
    },
    next() {
      if (this.$refs.form.validate()) {
        this.$bus.$emit("nextStepper");
      }
    },
    back() {
      this.$bus.$emit("previousStepper");
    },
  },
  created() {
    if (this.dataRegistration?.representative?.address?.zipCode) {
      this.residentialAdress = this.dataRegistration.representative.address.street;
      this.residentialNeighborhood = this.dataRegistration.representative.address.neighborhood;
      this.residentialCity = this.dataRegistration.representative.address.city;
      this.residentialState = this.dataRegistration.representative.address.state;
      this.residentialComplement = this.dataRegistration.representative.address.complement;
      this.residentialNumber = this.dataRegistration.representative.address.number;
      this.zipCode = this.dataRegistration.representative.address.zipCode;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/login/registration/otherSteps.scss';
</style>
