<template>
  <div class="form">
    <v-alert class="alerta" dense outlined v-if="error">{{error}}</v-alert>
    <v-form ref="form" v-model="valid" autocomplete="off" lazy-validation hide- @submit.prevent="submit">
      <v-text-field
        class="dataRegistration pb-md-5"
        v-model="dataRegistration.email"
        autocomplete="new-email"
        validate-on-blur
        :rules="emailRules"
        label="E-mail"
        placeholder="Insira seu e-mail"
        required
      ></v-text-field>
      <v-text-field
        class="dataRegistration pb-md-5 pt-5 pb-10"
        v-model="dataRegistration.emailConfirmation"
        :rules="emailConfirmationRules"
        validate-on-blur
        label="Confirmar E-mail"
        placeholder="Insira novamente seu e-mail"
        required
      ></v-text-field>
      <v-row class="pb-10">
        <v-col md="6" cols="12">
          <v-text-field
            class="dataRegistration"
            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            v-model="dataRegistration.password"
            autocomplete="new-password"
            validate-on-blur
            :rules="passwordRules"
            label="Senha"
            placeholder="Inserir Senha"
            required
          ></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            class="dataRegistration"
            :append-icon="showPasswordConfirm ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            :type="showPasswordConfirm ? 'text' : 'password'"
            @click:append="showPasswordConfirm = !showPasswordConfirm"
            v-model="dataRegistration.passwordConfirm"
            :rules="passwordConfirmationRules"
            label="Confirmar Senha"
            validate-on-blur
            placeholder="Confirmar senha"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-checkbox
        v-model="checkbox"
        :rules="termRules"
        color="light-blue lighten-1"
        :dark="checkbox"
        :light="!checkbox"
        class="pb-md-3 ml-md-3"
      >
        <template v-slot:label>
          <div @click.stop.prevent="downloadFile">Aceito o Contrato da Conta Digital Finago</div>
        </template>
      </v-checkbox>
      <v-row class="buttons">
        <v-col cols="12" md="12" class="pa-md-1">
            <v-btn
              rounded
              large
              block
              color="#D70472"
              :loading="btnLoading"
              dark
              @click="send"
            >Continuar</v-btn>
        </v-col>
        <v-col md="12" align="center" @click="back">
          <span class="back-button">Voltar</span>
        </v-col>
      </v-row>
    </v-form>
  </div>
  
</template>


<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import globalService from "../../../service";

export default {
  name: "SixthStep",
  data: () => ({
    valid: true,
    checkbox: false,
    showPasswordConfirm: false,
    showPassword: false,
    btnLoading: false,
    termRules: [(valueInput) => !!valueInput || "Campo obrigatório"],
    emailRules: [
      (valueInput) => !!valueInput || "E-mail obrigatório",
      (valueInput) => /.+@.+\..+/.test(valueInput) || "E-mail inválido",
    ],
    passwordRules: [
      (valueInput) => !!valueInput || "Senha obrigatória",
      (valueInput) =>
        (valueInput && valueInput.length >= 8) ||
        "Senha deve ter até 8 caracteres ou mais",
      (valueInput) =>
        (valueInput && valueInput.match(/\W/)) ||
        "Senha deve ter ao menos um caracter especial",
      (valueInput) =>
        (valueInput && valueInput.match(/[A-Z]/)) ||
        "Senha deve ter ao menos um caracter maiúsulo",
      (valueInput) =>
        (valueInput && valueInput.match(/[0-9]/)) ||
        "Senha deve ter ao menos um número",
    ],
  }),

  computed: {
    ...mapGetters({
      error: "Registration/getError",
      linkContract: 'Registration/getLinkContract',
    }),
    createdLogin() {
      return this.$store.state.Auth.messages;
    },
    emailConfirmationRules() {
      return [
        () =>
          this.dataRegistration.email ===
            this.dataRegistration.emailConfirmation ||
          "E-mails devem ser iguais",
        (valueInput) => !!valueInput || "E-mail de confirmação obrigatório",
      ];
    },

    passwordConfirmationRules() {
      return [
        () =>
          this.dataRegistration.password ===
            this.dataRegistration.passwordConfirm || "Senhas devem ser iguais",
        (valueInput) => !!valueInput || "Senha de confirmação obrigatória",
      ];
    },
    dataRegistration() {
      return this.$store.state.Registration.dataRegistration;
    },
  },

  methods: {
    ...mapActions({
      createUser: "Registration/createUser",
      createCustomer: "Registration/createCustomer",
      createContract: "Registration/createContract",
      downloadContract: "Registration/downloadContract"
    }),
    ...mapMutations({
      clearError: "Registration/CLEAR_ERROR",
      clearDataRegistration: "Registration/CLEAR_DATA_REGISTRATION",
    }),
    async send() {
      if (!this.$refs.form.validate()) return; 
      this.btnLoading = true;
      this.dataRegistration.document = globalService.cleanDocument(
        this.dataRegistration.document
      );
      this.dataRegistration.monthlyIncome = globalService.cleanCurrency(
        this.dataRegistration.monthlyIncome
      );
      let accountNumber = this.dataRegistration.accountNumber;
      this.dataRegistration.accountNumber = accountNumber.substring(0, accountNumber.length - 1);

      const birthDate = this.$moment(
        this.dataRegistration.birthDate,
        "DD/MM/YYYY"
      );
      const representativeBirthDate = this.$moment(
        this.dataRegistration.representative.birthDate,
        "DD/MM/YYYY"
      );
      this.dataRegistration.contract = this.linkContract;
      const {
        emailConfirmation,
        passwordConfirm,
        ...rest
      } = this.dataRegistration;
      this.dataRegistration.stateSubscription =
        this.dataRegistration.stateSubscription &&
        `${this.dataRegistration.stateSubscription}`;

      rest.representative.birthDate = representativeBirthDate;

      await this.createCustomer({ ...rest, birthDate });
      if (this.error) {
        this.dataRegistration.representative.birthDate = this.$moment(this.dataRegistration.representative.birthDate).format("DD/MM/YYYY");
        this.dataRegistration.accountNumber = this.dataRegistration.accountNumber + this.dataRegistration.accountDigitNumber;
        this.btnLoading = false;
        return;
      }
      this.clearDataRegistration();
      this.createdLogin.created = true;
      this.$router.push({ name: "login" });
    },
    back() {
      this.clearError();
      this.$bus.$emit("previousStepper");
    },
    async downloadFile() {
      const url = await this.downloadContract({profile: this.dataRegistration.profile[0], key: this.dataRegistration.segment});
      window.open(url);
    }
  },
  async created() {
    await this.createContract({profile: this.dataRegistration.profile[0], key: this.dataRegistration.segment})
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/login/registration/otherSteps.scss';
</style>
